<template>
  <div class="default-router">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "default-router"
}
</script>

<style scoped lang="scss">
  .default-router{
    height: 100vh;
    padding: 10px;
    width: 100%;
  }
</style>